import React, { FunctionComponent, useEffect, useMemo, useReducer, useState } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Stack, Typography } from '@mui/material';

import { IMenuItem, IReducerAction } from '@luxon/interfaces';
import { Button, Checkbox, FormDateInput, FormSelectInput, FormTextInput, FormV2 } from '@luxon/components';
import dayjs from 'dayjs';

type TConfirmActions = 'OPEN' | 'CLOSE';

interface IConfirmCaptureInputConfig {
    label: string;
    inputType?: 'text' | 'email' | 'select' | 'tel' | 'date';
    multiline?: boolean;
    rows?: number;
    required?: boolean;
    maxLength?: number;
    helperText?: string;
    options?: IMenuItem[];
}

interface IConfirmConfig {
    title?: string;
    message: string | React.ReactNode;
    onPositive?: (capturedInput?: any) => void | Promise<void>;
    positiveText?: string;
    onNegative?: () => void | Promise<void>;
    acceptanceTexts?: string[];
    captureInputConfigs?: Record<string, IConfirmCaptureInputConfig>;
}

interface IConfirmContext {
    confirm: (config: IConfirmConfig) => void;
    isOpen: boolean;
    config: IConfirmConfig | null;
}

const initialState: IConfirmContext = {
    confirm: () => null,
    isOpen: false,
    config: null
};
export const ConfirmContext = React.createContext<IConfirmContext>(initialState);

const ConfirmContextReducer = (state: IConfirmContext, action: IReducerAction<TConfirmActions>): IConfirmContext => {
    switch (action.type) {
        case 'OPEN':
            return {...state, isOpen: true, config: action.payload};
        case 'CLOSE':
            return {...state, isOpen: false, config: null};
        default:
            return state;
    }
};

interface IConfirmProviderProps {
    children: any;
};
export const ConfirmContextProvider: FunctionComponent<IConfirmProviderProps> = (props: IConfirmProviderProps) => {
    const [state, dispatch] = useReducer(ConfirmContextReducer, {...initialState,
        confirm: (config: IConfirmConfig) => {
            const updatedFormData: any = {};
            for (const key in config?.captureInputConfigs ?? {}) {
                updatedFormData[key] = config.captureInputConfigs[key].inputType === 'date' ? dayjs().startOf('day') : ''
            }
            setCaptureInputFormData(updatedFormData);

            dispatch({
                type: 'OPEN',
                payload: config
            });
        }
    });

    const [captureInputFormData, setCaptureInputFormData] = useState<any>({});
    const shouldCaptureInputs = useMemo(() => Object.keys(state.config?.captureInputConfigs ?? {}).length > 0, [state.config?.captureInputConfigs]);

    const [isLoading, setIsLoading] = useState(false);
    const [acceptedStates, setAcceptedStates] = useState<{[key: string]: boolean}>({});
    const didAccept = useMemo(() => {
        return Object.keys(acceptedStates).filter(x => !acceptedStates[x]).length === 0;
    }, [acceptedStates]);

    const handleAcceptanceTextCheckedChanged = (text: string, checked: boolean) => {
        const acceptedStatesCopy = Object.assign({}, acceptedStates);
        acceptedStatesCopy[text] = checked;
        setAcceptedStates(acceptedStatesCopy);
    };

    const handleNegativeClose = async () => {
        if (state.config?.onNegative) {
            await Promise.resolve(state.config.onNegative());
        }
        dispatch({ type: 'CLOSE' });
    }

    const handlePositive = async () => {
        if (state.config?.onPositive) {
            setIsLoading(true);
            try {
                const capturedInput = shouldCaptureInputs ? captureInputFormData : undefined;
                await Promise.resolve(state.config.onPositive(capturedInput));
            } finally {
                setIsLoading(false);
            }
        }
        dispatch({type: 'CLOSE'});
    }

    useEffect(() => {
        const acceptedStatesCopy: {[key: string]: boolean} = {};
        if (state.config?.acceptanceTexts?.length > 0) {
            for (const text of state.config.acceptanceTexts) {
                acceptedStatesCopy[text] = false;
            }
        }
        setAcceptedStates(acceptedStatesCopy);

        const updatedCaptureInputFormData: any = {};
        Object.keys(state.config?.captureInputConfigs ?? {}).forEach(key => {
            updatedCaptureInputFormData[key] = '';
        });
        setCaptureInputFormData(updatedCaptureInputFormData);
    }, [state.config]);

    return (
        <ConfirmContext.Provider value={state}>
            {props.children}

            <Dialog
                open={state.isOpen}
                onClose={handleNegativeClose}
            >
                <FormV2
                    formData={captureInputFormData}
                    formDataChanged={setCaptureInputFormData}
                    onSubmit={handlePositive}
                    disabled={isLoading}
                >
                    <DialogTitle>{state.config?.title ?? 'Are you sure?'}</DialogTitle>
                    <DialogContent>
                        {state.config?.message && typeof state.config?.message === 'string' ? (
                            <Typography variant='body2' sx={{
                                whiteSpace: 'break-spaces'
                            }}>
                                {state.config?.message}
                            </Typography>
                        ) : state.config?.message ? state.config?.message : null}

                        {shouldCaptureInputs && (
                            <Stack marginTop={2}>
                                {Object.keys(state.config.captureInputConfigs).map((key) => state.config.captureInputConfigs[key].inputType === 'select' ? (
                                    <FormSelectInput<any>
                                        key={key}
                                        name={key}
                                        options={state.config.captureInputConfigs[key].options ?? []}
                                        label={state.config.captureInputConfigs[key].label}
                                        required={state.config.captureInputConfigs[key].required}
                                        helperText={state.config.captureInputConfigs[key].helperText}
                                    />
                                ) : state.config.captureInputConfigs[key].inputType === 'date' ? (
                                    <FormDateInput<any>
                                        key={key}    
                                        name={key}
                                        label={state.config.captureInputConfigs[key].label}
                                        required={state.config.captureInputConfigs[key].required}
                                        helperText={state.config.captureInputConfigs[key].helperText}
                                    />
                                ) : (
                                    <FormTextInput<any>
                                        key={key}
                                        name={key}
                                        type={state.config.captureInputConfigs[key].inputType as 'text' | 'email' | 'tel'}
                                        label={state.config.captureInputConfigs[key].label}
                                        multiline={state.config.captureInputConfigs[key].multiline}
                                        minRows={state.config.captureInputConfigs[key].rows}
                                        maxRows={state.config.captureInputConfigs[key].rows}
                                        required={state.config.captureInputConfigs[key].required}
                                        maxLength={state.config.captureInputConfigs[key].maxLength}
                                        helperText={state.config.captureInputConfigs[key].helperText}
                                    />
                                ))}
                            </Stack>
                        )}

                        {state.config?.acceptanceTexts?.length > 0 && (
                            <Stack marginTop={2} spacing={0}>
                                {
                                    state.config?.acceptanceTexts.map(text => (
                                        <Checkbox
                                            size='small'
                                            key={text}
                                            label={text}
                                            value={acceptedStates[text]}
                                            onChange={(checked) => handleAcceptanceTextCheckedChanged(text, checked)}
                                        />
                                    ))
                                }
                            </Stack>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='text'
                            color='error'
                            type='button'
                            onClick={handleNegativeClose}
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='text'
                            type={shouldCaptureInputs ? 'submit' : 'button'}
                            onClick={() => shouldCaptureInputs ? null : handlePositive()}
                            loading={isLoading}
                            disabled={!didAccept}
                        >
                            {state.config?.positiveText ?? 'Continue'}
                        </Button>
                    </DialogActions>
                </FormV2>
            </Dialog>
        </ConfirmContext.Provider>
    );
}